import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { VerifyService } from 'src/app/services/verify.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.css']
})
export class VerifyComponent implements OnInit {
  invalid: any = true;
  a:any;
  b:any;
  c:any;
  d:any;

  constructor(private _verify:VerifyService,private _route: Router) { }

  ngOnInit(): void {
  }

  check(){
    console.log('e',VerifyService.email_id);
    
    let verifyData = {
      email_id: VerifyService.email_id,
      otp_code: this.a + this.b + this.c + this.d,
    };
    console.log('otp_code',verifyData.otp_code);
    
    let formData = new FormData();
    formData.append('email_id',verifyData.email_id);
    formData.append('otp_code',verifyData.otp_code);
    console.log('formData',formData);
    
    this._verify.check(formData).subscribe(result =>{
     console.log(result);
      // let result = {
      //   status:200
      // }
      if (result.status==200) {
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-danger"
          },
          buttonsStyling: false
        });
    
        swalWithBootstrapButtons
          .fire({
            title: "OTP matched successfully.",
            icon: "success",
            confirmButtonText: "Create new password",
            reverseButtons: false
          })
          .then(result => {
            if (result.value) {
                console.log('new password screen');
                this._route.navigate(['/newpassword'])
            } else if (result.dismiss === Swal.DismissReason.cancel) {
            }
          });
      }else this.invalid = false;
    }, err => {
      this.invalid = false;
    }
    )
    
  }

  resend(){
    console.log('verifyemail',VerifyService.email_id);
    
    let verifyData = {
      email_id: VerifyService.email_id,
    };
    let formData = new FormData();
    formData.append('email_id',verifyData.email_id)
    this._verify.resend(formData).subscribe(result =>{
     console.log(result);
      //let result = {
        //status:200
      //}
      if (result.status==200) {
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-danger"
          },
          buttonsStyling: false
        });
    
        swalWithBootstrapButtons
          .fire({
            title: "OTP sent to your registered email.",
            icon: "success",
            confirmButtonText: "Very OTP",
            reverseButtons: false
          })
          .then(result => {
            if (result.value) {
                
            } else if (result.dismiss === Swal.DismissReason.cancel) {
            }
          });
      }else this.invalid = false;
    }, err => {
     this.invalid = false;
    }
    )
    
  }

}


