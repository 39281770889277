<div class="sidebar" data-color="purple" data-background-color="white">
    <div class="logo">
        <!-- <a href="http://www.creative-tim.com" class="simple-text logo-mini">
      CT
    </a> -->
    <img src="https://document.getbookbag.com/Bookbag_logo_website.png" style="width: 215px; margin-left: 10px;">
        <!-- <a [routerLink]="['/home']" class="simple-text logo-normal">
            BOOKBAG | Admin
        </a> -->
    </div>
    <div class="sidebar-wrapper">
        <ul class="nav">
            <li class="nav-item " routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/dashboard']">
                    <i class="material-icons">dashboard</i>
                    <p>Dashboard</p>
                </a>
            </li>


            <li class="nav-item " routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/users']">
                    <i class="material-icons">people</i>
                    <p>Users</p>
                </a>
            </li>

            <li class="nav-item " routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/groups']">
                    <i class="material-icons">groups</i>
                    <p>Groups</p>
                </a>
            </li>

            <li class="nav-item " routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/books']">
                    <i class="material-icons">menu_book</i>
                    <p>Books</p>
                </a>
            </li>

            <li class="nav-item " routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/subjects']">
                    <i class="material-icons">book</i>
                    <p>Subjects</p>
                </a>
            </li>

            <li class="nav-item " routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/orders']">
                    <i class="material-icons">receipt</i>
                    <p>Orders</p>
                </a>
            </li>

            <li class="nav-item " routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/loans']">
                    <i class="material-icons">attach_money</i>
                    <p>Loans</p>
                </a>
            </li>

            <li class="nav-item " routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/payments']">
                    <i class="material-icons">payment</i>
                    <p>Payments</p>
                </a>
            </li>
            <li class="nav-item " routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/disputes']">
                    <i class="material-icons">feedback</i>
                    <p>Disputes</p>
                </a>
            </li>
            <li class="nav-item " routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/users/delete-requests']">
                    <i class="material-icons">feedback</i>
                    <p>User Delete Requests</p>
                </a>
            </li>

            <li class="nav-item " routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/common/change-password']">
                    <i class="material-icons">fingerprint</i>
                    <p>Change Password</p>
                </a>
            </li>
        </ul>
    </div>
</div>