import { Component, OnInit } from '@angular/core';
import   Swal  from 'sweetalert2';
import { VerifyService } from 'src/app/services/verify.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  invalid: any = true;
  email_id: any;

  constructor(private _verify:VerifyService, private _route: Router) { }

  ngOnInit(): void {
  }

  verify(email_id){
    
    let verifyData = {
      email_id: this.email_id,
    };
    let formData = new FormData();
    formData.append('email_id',verifyData.email_id)
    this._verify.verify(formData).subscribe(result =>{
     console.log(result);
      //let result = {
        //status:200
      //}
      if (result.status==200) {
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-danger"
          },
          buttonsStyling: false
        });
    
        swalWithBootstrapButtons
          .fire({
            title: "OTP sent to your registered email.",
            icon: "success",
            confirmButtonText: "Very OTP",
            reverseButtons: false
          })
          .then(result => {
            if (result.value) {
                console.log('verify otp screen',result.value);
                VerifyService.email_id = email_id
                console.log('V',VerifyService.email_id);
                
                this._route.navigate(['/verify'])
            } else if (result.dismiss === Swal.DismissReason.cancel) {
            }
          });
      }else this.invalid = false;
    }, err => {
     this.invalid = false;
    }
    )
    
  }

}
