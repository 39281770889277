import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { HttpcommanService } from "./httpshared.service";
@Injectable({
  providedIn: "root"
})
export class CommanService {
  constructor(
    private http: HttpClient,
    private httpService: HttpcommanService
  ) {}
  changePassword(params: any) {
    return this.httpService.patchCall('admin/auth/change-password', params);
   
  }

  pushBroadCast(data: any) {
    return this.httpService.postCall('pushBroadcast', data);   
  }


  //Access from feedback component
  getAllFeedbacks(){
    return this.httpService.getCall('admin/feedbacks');
  }
  //Accessing from feedback component
  deleteFeedback(feedbackId:any){
    return this.httpService.deleteCall('admin/deleteFeedback',feedbackId);
  }
}
