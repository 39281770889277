import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpcommanService } from './httpshared.service';

@Injectable({
  providedIn: 'root'
})
export class VerifyService {
  static email_id: any;

  constructor(private http: HttpClient,
    private httpService: HttpcommanService) { }

  verify(data: any) {
    console.log('data', data);

    return this.httpService.postCall("admin/auth/forgot", data);
  }

  check(data: any) {
    console.log('data', data);

    return this.httpService.postCall("admin/auth/checkotp", data);
  }
  resend(data: any) {
    return this.httpService.postCall("admin/auth/resend", data);
  }
  newpass(data: any) {
    return this.httpService.patchCall("admin/auth/newpassword", data);
  }
}
