import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from "./login/login.component";
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { PreloadAllModules } from "@angular/router";
import { ProtectGuard } from './guards/protect.guard'
import { LogoutGuard } from './guards/logout.guard';
import { AuthGuard } from './guards/auth.guard';
import { VerifyComponent } from './login/verify/verify.component';
import { NewPasswordComponent } from './login/new-password/new-password.component';
const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "*", redirectTo: "login", pathMatch: "full" },
  {
    path: "login",
    canActivate: [ProtectGuard],
    component: LoginComponent
  },
  {
    path: "forgot",
    canActivate: [ProtectGuard],
    component: ForgotPasswordComponent
  },
  {
    path: "verify",
    canActivate: [ProtectGuard],
    component: VerifyComponent
  },
  {
    path: "newpassword",
    canActivate: [ProtectGuard],
    component: NewPasswordComponent
  },
  {
    path: "dashboard",
    canActivate: [AuthGuard],
    loadChildren: () => import("./modules/dashboard/dashboard.module").then(m => m.DashboardModule)
  },

  {
    path: "common",
    canActivate: [AuthGuard],
    loadChildren: () => import("./modules/shared/shared.module").then(m => m.SharedModule)
  },
  {
    path: "users",
    canActivate: [AuthGuard],
    loadChildren: () => import("./modules/users/users.module").then(m => m.UsersModule)
  },
  {
    path: "groups",
    canActivate: [AuthGuard],
    loadChildren: () => import("./modules/groups/groups.module").then(m => m.GroupsModule)
  },
  {
    path: "books",
    canActivate: [AuthGuard],
    loadChildren: () => import("./modules/books/books.module").then(m => m.BooksModule)
  },
  {
    path: "subjects",
    canActivate: [AuthGuard],
    loadChildren: () => import("./modules/subject/subject.module").then(m => m.SubjectModule)
  },
  {
    path: "orders",
    canActivate: [AuthGuard],
    loadChildren: () => import("./modules/orders/orders.module").then(m => m.OrdersModule)
  },
  {
    path: "loans",
    canActivate: [AuthGuard],
    loadChildren: () => import("./modules/loans/loans.module").then(m => m.LoansModule)
  },
  {
    path: "payments",
    canActivate: [AuthGuard],
    loadChildren: () => import("./modules/payments/payments.module").then(m => m.PaymentsModule)
  },
  {
    path: "disputes",
    canActivate: [AuthGuard],
    loadChildren: () => import("./modules/disputes/disputes.module").then(m => m.DisputesModule)
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      preloadingStrategy: PreloadAllModules
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
