import { BroadcastingComponent } from './components/broadcasting/broadcasting.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChangepasswordComponent } from './components/changepassword/changepassword.component';
import { FeedbackComponent} from './components/feedback/feedback.component';
import { AuthGuard } from '../../guards/auth.guard';
//import { CreateComponent } from '../announcements/create/create.component';
const routes: Routes = [ {
  path: '',
  redirectTo: 'adlist',
  pathMatch: 'full',
},
{ path: 'change-password',
  canActivate:[AuthGuard],
  component: ChangepasswordComponent,
 // loadChildren: () =>import("./shared.module").then(m => m.SharedModule)    
 
 },
{ path: 'notifications', component: BroadcastingComponent },
{ path: 'feedbacks', component: FeedbackComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SharedRoutingModule { }
