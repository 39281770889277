import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { VerifyService } from 'src/app/services/verify.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.css']
})
export class NewPasswordComponent implements OnInit {
  invalid: boolean;
  password:any;
  retypepassword:any;

  constructor(private _verify:VerifyService,private _route: Router) { }

  ngOnInit(): void {
  }
  newpass(){
    console.log('pass',this.password,this.retypepassword);
    
    if (this.password == this.retypepassword) {
      console.log('>>>>>>>>');
      
      let verifyData = {
     email_id: VerifyService.email_id,
     password:this.password
    };
    let formData = new FormData();
    formData.append('email_id',verifyData.email_id);
    formData.append('password',verifyData.password);
    this._verify.newpass(formData).subscribe(result =>{
     console.log(result);
    //  let result = {
    //   status:200
    // }
    if (result.status==200) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });
  
      swalWithBootstrapButtons
        .fire({
          title: "New password created successfully.",
          icon: "success",
          confirmButtonText: "Go To Login Page",
          reverseButtons: false
        })
        .then(result => {
          if (result.value) {
              this._route.navigate(['/login'])
          } else if (result.dismiss === Swal.DismissReason.cancel) {
          }
        });
    }else this.invalid = false;
  }, err => {
    this.invalid = false;
  }
  )
    }
    
  }
}
