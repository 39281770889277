import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { LoginComponent } from './login/login.component';
import { SharedModule } from './modules/shared/shared.module';
import { AngularmaterialModule } from './modules/angularmaterial/angularmaterial.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { httpInterceptorProvider } from './providers/httpInterceptor';
import { hashLocation } from './providers/hashLocation';
import { NgxPaginationModule } from 'ngx-pagination';
import { JwtService } from './services/jwt.services';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { VerifyComponent } from './login/verify/verify.component';
import { NewPasswordComponent } from './login/new-password/new-password.component';
import { OrderlistComponent } from './modules/orders/components/orderlist/orderlist.component';

//import { NgxSpinnerModule } from "ngx-spinner";

@NgModule({
  declarations: [AppComponent, LoginComponent, ForgotPasswordComponent, VerifyComponent, NewPasswordComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularmaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    BrowserAnimationsModule,
    SharedModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    // NgxSpinnerModule,
    NgHttpLoaderModule.forRoot(), // <============ Don't forget to call 'forRoot()'!
  ],
  exports: [AngularmaterialModule, SharedModule],
  providers: [hashLocation, httpInterceptorProvider,JwtService],
  bootstrap: [AppComponent],
})
export class AppModule {}
