<div class="login-page ">
    <nav class="navbar navbar-color-on-scroll navbar-transparent    fixed-top  navbar-expand-lg " color-on-scroll="100"
        id="sectionsNav">
        <div class="container">
            <div class="navbar-translate">
                <a class="navbar-brand text-white">BookBag | Admin</a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                    <span class="navbar-toggler-icon"></span>
                    <span class="navbar-toggler-icon"></span>
                </button>
            </div>
            <div class="collapse navbar-collapse">

            </div>
        </div>
    </nav>
    <div class="page-header header-filter"
        style="background-image: url(https://document.getbookbag.com/Admin_bookbag.png); background-size: cover; background-position: top center;">
        <div class="container">
            <div class="row">
                <div class="col-md-4 col-sm-6 ml-auto mr-auto d-flex" style="min-height: 100vh;">
                    <div class="card card-signup align-self-center">
                        <form class="form" method="" action="">
                            <div class="card-header card-header-primary text-center">
                                <h4 class="card-title">OTP Send</h4>

                            </div>
                            <p class="description text-center">Or Be Classical</p>
                            <div class="card-body">
                                <p class="text-center "><b>Please check email for OTP</b></p>
                                <p class="text-center "><b>Enter 4 Digit OTP</b></p>
                                <div class="input-group mb-4">
                                    <input type="text" class="form-control" placeholder="0" name="OTP" maxlength="1" [(ngModel)]="a"/>
                                    <input type="text" class="form-control" placeholder="0" name="OTP" maxlength="1" [(ngModel)]="b"/>
                                    <input type="text" class="form-control" placeholder="0" name="OTP" maxlength="1" [(ngModel)]="c"/>
                                    <input type="text" class="form-control" placeholder="0" name="OTP" maxlength="1" [(ngModel)]="d"/>
                                </div>
                                <!-- <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <i class="material-icons">lock_outline</i>
                                        </span>
                                    </div>
                                    <input type="password" class="form-control" placeholder="Password..."
                                     name="password" />
                                </div> -->
                            </div>
                            <div class="footer">
                                <div class="w-100 text-center">
                                    <a class="btn btn-primary text-white"(click)="check()">Verify OTP </a>
                                </div>
                            </div>
                            <p class="text-center"><b>OTP not received?&nbsp;</b><a (click)="resend()" style="cursor: pointer;">Resent OTP</a></p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>