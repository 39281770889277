<div class="wrapper ">
  <app-sidebar></app-sidebar>
  <div class="main-panel">
    <!-- Navbar -->
    <app-header></app-header>
    <div class="content">
      <div class="container-fluid">
        <div class="row" style="margin-top:-50px;">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header card-header-primary">
                <h4 class="card-title ">Change Password</h4>
                <!-- <p class="card-category">Here is a subtitle for this table</p> -->
              </div>
              <div class="card-body">
                <form [formGroup]="changePasswordForm">
                  <div class="row">
                    <div class="col-md-1"></div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="bmd-label-floating">Old Password</label>
                        <input type="password" (keydown.space)="$event.preventDefault();" formControlName="oldPassword" name="oldPassword" class="form-control" />
                      </div>
                      <div *ngIf="submitted && f.oldPassword.errors" class=" text-left">
                        <div *ngIf="f.oldPassword.errors.required" class="validation_color text-danger">
                          Old Password is required !
                        </div>
                        <div *ngIf="f.oldPassword.errors.minlength" class="validation_color text-danger">
                           Password must be at least 6 characters.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-1"></div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="bmd-label-floating">New Password</label>
                        <input (keydown.space)="$event.preventDefault();" type="password" class="form-control" formControlName="newPassword" name="newPassword" />
                      </div>
                      <div *ngIf="submitted && f.newPassword.errors" class=" text-left">                      
                        <div *ngIf="f.newPassword.errors.required" class="validation_color text-danger">
                          New Password is required !                          
                        </div>
                        <div *ngIf="f.newPassword.errors.minlength" class="validation_color text-danger">
                           Password must be at least 6 characters.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-1"></div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="bmd-label-floating">Confirm Password</label>
                        <input
                          type="password"
                          class="form-control"
                          formControlName="confirmPassword"
                          name="confirmPassword"
                          (keydown.space)="$event.preventDefault();"
                        />
                      </div>
                      <div *ngIf="submitted && f.confirmPassword.errors" class=" text-left">
                        <div *ngIf="f.confirmPassword.errors.required" class="validation_color text-danger">
                          Confirm Password is required !
                        </div>
                        <div *ngIf="f.confirmPassword.errors.minlength" class="validation_color text-danger">
                           Password must be at least 6 characters.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-1"></div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <button class="btn btn-primary btn-md" (click)="saveDetails()">Change Password</button>
                      </div>
                    </div>
                    <div class="col-md-1"></div>
                    <div class="col-md-2"></div>
                  </div>
                  <br />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
