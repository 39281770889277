export const environment = {
  production: false,
  googleKey:"AIzaSyBUKpzHeU7PPR5y_AizLYYVZ20YnSyWx58",
  // apiUrl:"http://localhost:3000/api/v1/",
  apiUrl: 'https://api.getbookbag.com/api/v1/',
  imagePath:'https://storage.googleapis.com/bookbag-dev/'
};



