import { Component, OnInit } from '@angular/core';
import { LoginService } from '../services/login.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  invalid: any = true;
  email_id: any;
  password: any;
  constructor(private _login: LoginService, private _route: Router) {}

  ngOnInit() {}

  login() {
    let loginData = {
      email_id: this.email_id,
      password: this.password,
    };
    let formData = new FormData();
    formData.append('email_id',loginData.email_id)
    formData.append('password',loginData.password)
    this._login.login(formData).subscribe(
      result => {
        console.log(result);
        if (result.status == 200) {
          localStorage.setItem('isLoggedIn', 'true');
          localStorage.setItem('token', result.data.token);
          this._route.navigate(['/dashboard']);
        } else this.invalid = false;
      },
      err => {
        this.invalid = false;
      }
    );
     
  }
}
