  import { Component, OnInit } from '@angular/core';
  import { Router } from '@angular/router';
  import Swal from 'sweetalert2'
  @Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
  })
  export class HeaderComponent implements OnInit {
    constructor(private _route: Router) {}

    ngOnInit() {}
    //[routerLink]="['/logout']"
    logout() {
      Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to logout!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#A43EB8',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Logout!'
      }).then((result) => {
        if (result.isConfirmed) {
          sessionStorage.clear();    
          localStorage.setItem('token',null);  
          localStorage.setItem('isLoggedIn', 'false');
          localStorage.clear();
          this._route.navigate(['/login']);      
        }
      })

    
    }
  }
